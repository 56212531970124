.forgetPassword {
  color: inherit;
  opacity: 0.5;
  float: right;
  margin-top: 10px;
  -webkit-transition: ease var(--delay-normal);
  -o-transition: ease var(--delay-normal);
  transition: ease var(--delay-normal);
}

.forgetPassword:hover,
.forgetPassword:active,
.forgetPassword:focus {
  opacity: 1;
  -webkit-transition: ease var(--delay-normal);
  -o-transition: ease var(--delay-normal);
  transition: ease var(--delay-normal);
}

.login {
  overflow-x: hidden;
}

.login .login--inner .left {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 20px;
}
.login .login--inner .left .background-container {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  background-image: url(./news01.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.login .login--inner .left .background-container:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-color);
}
.login .login--inner .left .background-container:before {
  backdrop-filter: blur(1px);
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0.5);
}
.login .login--inner .left .logo {
  max-width: 250px;
  max-width: 25rem;
  width: 100%;
  margin: 30px auto;
  margin: 3rem auto;
  text-align: center;
}
.login .login--inner .left .logo .logo--icon svg {
  fill: rgba(255, 255, 255, 255, 0.15);
  max-width: 60%;
  margin-bottom: 14px;
  margin-bottom: 1.4rem;
}
.login .login--inner .left .logo .logo--text svg {
  fill: rgba(255, 255, 255, 255, 0.15);
}
.login .login--inner .left .big {
  font-weight: 600;
  font-size: 30px;
  font-size: 2.2rem;
  text-transform: uppercase;
  margin: 2rem;
}
.login .login--inner .right {
  min-height: 100vh;
}
.login .login--inner .right .container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.login .login--inner .right .container .login--form {
  /* margin-top: auto;
  margin-bottom: 40px;
  margin-bottom: 4rem; */
  padding-top: 20px;
  /* padding-top: 4rem; */
}
.login .login--inner .right .container .login--form .title h1 {
  font-weight: 500;
  font-size: 30px;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-bottom: 3rem;
}

.login .login--inner .right footer .container {
  padding: 10px 0;
  /* padding: 2rem 0; */
}
.login .login--inner .right footer .logos {
  list-style: none;
  padding: 0;
  margin: 0;
}
.login .login--inner .right footer .logos li {
  display: inline-block;
}
.login .login--inner .right footer .logos li:not(:last-child) {
  margin-right: 20px;
  margin-right: 2rem;
}
