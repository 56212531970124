@charset "UTF-8";
@import url("../fonts/work-sans/stylesheet.css");

/*  __      __        _       _     _           
/*  \ \    / /       (_)     | |   | |          
/*   \ \  / /_ _ _ __ _  __ _| |__ | | ___  ___ 
/*    \ \/ / _` | '__| |/ _` | '_ \| |/ _ \/ __|
/*     \  / (_| | |  | | (_| | |_) | |  __/\__ \
/*      \/ \__,_|_|  |_|\__,_|_.__/|_|\___||___/
/*                                              
/*                                               */
/* Variables */
:root {
    /* Fonts */
    --font-work_sans: "Work Sans", sans-serif;

    /* Delay */
    --delay-normal: 0.3s;
    --delay-slow: 1s;
    --delay-fast: 0.1s;

    /* Colors */
    --eba-color: linear-gradient(73.36deg, #05173a 0.44%, #6b9ac0 93.44%);
    --black-color: #0c090a;
    --white-color: #ffffff;
    --dark-color: #181e2c;
    --lighter-dark-color: #212734;
    --eba-light: #f5f5f5;
    --light-color: #f5f5f5;
    --bs-primary: #0a3d80;
    --gray6-color: #f2f2f2;
    --gray5-color: #e0e0e0;
    --gray4-color: #bdbdbd;
    --gray3-color: #828282;
    --gray2-color: #4f4f4f;
    --gray1-color: #333333;
    --boxShadow: 0px 20px 30px -20px rgba(0, 0, 0, 0.5);

    /* Social Media */
    --facebook: #1877f2;
    --facebookold: #3b5998;
    --twitter: #1da1f2;
    --youtube: #ff0000;
    --instagrammagenta: #c32aa3;
    --instagramblue: #4c5fd7;
    --instagrampurple: #7232bd;
    --instagramorange: #f46f30;
    --instagramyellow: #ffdc7d;
    --googleblue: #4285f4;
    --googlered: #ea4335;
    --googleyellow: #fbbc05;
    --googlegreen: #34a853;
    --pinterest: #bd081c;
    --googleplus: #db4437;
    --linkedin: #007bb5;
    --vimeoblue: #1ab7ea;
    --tumblr: #2c4762;
    --snapchat: #fffc00;
    --whatsappgreen: #25d366;
    --whatsappteal1: #075e54;
    --whatsappteal2: #128c7e;
    --tiktokblack: #010101;
    --tiktookblue: #69c9d0;
    --tiktokpink: #ee1d52;
    --tiktokwhite: #fff;
    --mastodon: #2b90d9;
    --apple: #a6b1b7;
    --amazon: #ff9900;
    --alexablue: #00a7ce;
    --alexadkblue: #232f3e;
    --microsoftred: #f35022;
    --microsoftgreen: #80bb03;
    --microsoftblue: #03a5f0;
    --microsoftyellow: #ffb903;
    --periscope: #40a4c4;
    --foursquarepink: #f94877;
    --foursquarenavy: #073282;
    --foursquareblue: #2d5be3;
    --yelp: #d32323;
    --swarm: #ffa633;
    --medium: #02b875;
    --skypeblue: #00aff0;
    --skypedkblue: #0078d7;
    --android: #a4c639;
    --stumbleupon: #e94826;
    --flickrpink: #f40083;
    --flickrblue: #006add;
    --yahoo: #430297;
    --twitch: #9146ff;
    --soundcloud: #ff5500;
    --spotifygreen: #1ed760;
    --spotifydarkgreen: #1db954;
    --dribbble: #ea4c89;
    --slackpurple: #4a154b;
    --slackblue: #36c5f0;
    --slackgreen: #2eb67d;
    --slackred: #e01e5a;
    --slackyellow: #ecb22e;
    --reddit: #ff5700;
    --deviantart: #05cc47;
    --pocket: #ee4056;
    --quora: #aa2200;
    --quorablue: #2b6dad;
    --slideshareorange: #e68523;
    --slideshareblue: #00a0dc;
    --fivehundredpx: #0099e5;
    --vk: #4a76a8;
    --listlyorange: #df6d46;
    --listlyblue: #52b1b3;
    --vine: #00b489;
    --steam: #171a21;
    --discord: #7289da;
    --telegram: #0088cc;
    --clarity: #61bed9;
    --homeadvisor: #f89000;
    --houzz: #4dbc15;
    --angieslist: #29a036;
    --glassdoor: #0caa41;
}

* {
    /* Typography */
    --main-font: var(--font-work_sans);

    /* Calculation for font size */
    --responsive: calc(
        (var(--min-font) * 1px) + (var(--max-font) - var(--min-font)) *
            ((100vw - 420px) / (1440 - 420))
    ); /* Ranges from 421px to 1399px */
}

#root {
    overflow-x: hidden;
}
/*    _____            _       _   __  __          _ _       
/*   / ____|          (_)     | | |  \/  |        | (_)      
/*  | (___   ___   ___ _  __ _| | | \  / | ___  __| |_  __ _ 
/*   \___ \ / _ \ / __| |/ _` | | | |\/| |/ _ \/ _` | |/ _` |
/*   ____) | (_) | (__| | (_| | | | |  | |  __/ (_| | | (_| |
/*  |_____/ \___/ \___|_|\__,_|_| |_|  |_|\___|\__,_|_|\__,_|
/*                                                           
/*                                                            */
/* Social Media */
[class*="social-"] {
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.social-facebook,
.social-facebook-hover:hover {
    color: var(--facebook);
}
.social-facebookold,
.social-facebookold-hover:hover {
    color: var(--facebookold);
}
.social-twitter,
.social-twitter-hover:hover {
    color: var(--twitter);
}
.social-youtube,
.social-youtube-hover:hover {
    color: var(--youtube);
}
.social-instagrammagenta,
.social-instagrammagenta-hover:hover {
    color: var(--instagrammagenta);
}
.social-instagramblue,
.social-instagramblue-hover:hover {
    color: var(--instagramblue);
}
.social-instagrampurple,
.social-instagrampurple-hover:hover {
    color: var(--instagrampurple);
}
.social-instagramorange,
.social-instagramorange-hover:hover {
    color: var(--instagramorange);
}
.social-instagramyellow,
.social-instagramyellow-hover:hover {
    color: var(--instagramyellow);
}
.social-googleblue,
.social-googleblue-hover:hover {
    color: var(--googleblue);
}
.social-googlered,
.social-googlered-hover:hover {
    color: var(--googlered);
}
.social-googleyellow,
.social-googleyellow-hover:hover {
    color: var(--googleyellow);
}
.social-googlegreen,
.social-googlegreen-hover:hover {
    color: var(--googlegreen);
}
.social-pinterest,
.social-pinterest-hover:hover {
    color: var(--pinterest);
}
.social-googleplus,
.social-googleplus-hover:hover {
    color: var(--googleplus);
}
.social-linkedin,
.social-linkedin-hover:hover {
    color: var(--linkedin);
}
.social-vimeoblue,
.social-vimeoblue-hover:hover {
    color: var(--vimeoblue);
}
.social-tumblr,
.social-tumblr-hover:hover {
    color: var(--tumblr);
}
.social-snapchat,
.social-snapchat-hover:hover {
    color: var(--snapchat);
}
.social-whatsappgreen,
.social-whatsappgreen-hover:hover {
    color: var(--whatsappgreen);
}
.social-whatsappteal1,
.social-whatsappteal1-hover:hover {
    color: var(--whatsappteal1);
}
.social-whatsappteal2,
.social-whatsappteal2-hover:hover {
    color: var(--whatsappteal2);
}
.social-tiktokblack,
.social-tiktokblack-hover:hover {
    color: var(--tiktokblack);
}
.social-tiktookblue,
.social-tiktookblue-hover:hover {
    color: var(--tiktookblue);
}
.social-tiktokpink,
.social-tiktokpink-hover:hover {
    color: var(--tiktokpink);
}
.social-tiktokwh,
.social-tiktokwh-hover:hover {
    color: var(--tiktokwh);
}
.social-mastodon,
.social-mastodon-hover:hover {
    color: var(--mastodon);
}
.social-apple,
.social-apple-hover:hover {
    color: var(--apple);
}
.social-amazon,
.social-amazon-hover:hover {
    color: var(--amazon);
}
.social-alexablue,
.social-alexablue-hover:hover {
    color: var(--alexablue);
}
.social-alexadkblue,
.social-alexadkblue-hover:hover {
    color: var(--alexadkblue);
}
.social-microsoftred,
.social-microsoftred-hover:hover {
    color: var(--microsoftred);
}
.social-microsoftgreen,
.social-microsoftgreen-hover:hover {
    color: var(--microsoftgreen);
}
.social-microsoftblue,
.social-microsoftblue-hover:hover {
    color: var(--microsoftblue);
}
.social-microsoftyellow,
.social-microsoftyellow-hover:hover {
    color: var(--microsoftyellow);
}
.social-periscope,
.social-periscope-hover:hover {
    color: var(--periscope);
}
.social-foursquarepink,
.social-foursquarepink-hover:hover {
    color: var(--foursquarepink);
}
.social-foursquarenavy,
.social-foursquarenavy-hover:hover {
    color: var(--foursquarenavy);
}
.social-foursquareblue,
.social-foursquareblue-hover:hover {
    color: var(--foursquareblue);
}
.social-yelp,
.social-yelp-hover:hover {
    color: var(--yelp);
}
.social-swarm,
.social-swarm-hover:hover {
    color: var(--swarm);
}
.social-medium,
.social-medium-hover:hover {
    color: var(--medium);
}
.social-skypeblue,
.social-skypeblue-hover:hover {
    color: var(--skypeblue);
}
.social-skypedkblue,
.social-skypedkblue-hover:hover {
    color: var(--skypedkblue);
}
.social-android,
.social-android-hover:hover {
    color: var(--android);
}
.social-stumbleupon,
.social-stumbleupon-hover:hover {
    color: var(--stumbleupon);
}
.social-flickrpink,
.social-flickrpink-hover:hover {
    color: var(--flickrpink);
}
.social-flickrblue,
.social-flickrblue-hover:hover {
    color: var(--flickrblue);
}
.social-yahoo,
.social-yahoo-hover:hover {
    color: var(--yahoo);
}
.social-twitch,
.social-twitch-hover:hover {
    color: var(--twitch);
}
.social-soundcloud,
.social-soundcloud-hover:hover {
    color: var(--soundcloud);
}
.social-spotifygreen,
.social-spotifygreen-hover:hover {
    color: var(--spotifygreen);
}
.social-spotifydarkgreen,
.social-spotifydarkgreen-hover:hover {
    color: var(--spotifydarkgreen);
}
.social-dribbble,
.social-dribbble-hover:hover {
    color: var(--dribbble);
}
.social-slackpurple,
.social-slackpurple-hover:hover {
    color: var(--slackpurple);
}
.social-slackblue,
.social-slackblue-hover:hover {
    color: var(--slackblue);
}
.social-slackgreen,
.social-slackgreen-hover:hover {
    color: var(--slackgreen);
}
.social-slackred,
.social-slackred-hover:hover {
    color: var(--slackred);
}
.social-slackyellow,
.social-slackyellow-hover:hover {
    color: var(--slackyellow);
}
.social-reddit,
.social-reddit-hover:hover {
    color: var(--reddit);
}
.social-deviantart,
.social-deviantart-hover:hover {
    color: var(--deviantart);
}
.social-pocket,
.social-pocket-hover:hover {
    color: var(--pocket);
}
.social-quora,
.social-quora-hover:hover {
    color: var(--quora);
}
.social-quorablue,
.social-quorablue-hover:hover {
    color: var(--quorablue);
}
.social-slideshareorange,
.social-slideshareorange-hover:hover {
    color: var(--slideshareorange);
}
.social-slideshareblue,
.social-slideshareblue-hover:hover {
    color: var(--slideshareblue);
}
.social-fivehundredpx,
.social-fivehundredpx-hover:hover {
    color: var(--fivehundredpx);
}
.social-vk,
.social-vk-hover:hover {
    color: var(--vk);
}
.social-listlyorange,
.social-listlyorange-hover:hover {
    color: var(--listlyorange);
}
.social-listlyblue,
.social-listlyblue-hover:hover {
    color: var(--listlyblue);
}
.social-vine,
.social-vine-hover:hover {
    color: var(--vine);
}
.social-steam,
.social-steam-hover:hover {
    color: var(--steam);
}
.social-discord,
.social-discord-hover:hover {
    color: var(--discord);
}
.social-telegram,
.social-telegram-hover:hover {
    color: var(--telegram);
}
.social-clarity,
.social-clarity-hover:hover {
    color: var(--clarity);
}
.social-homeadvisor,
.social-homeadvisor-hover:hover {
    color: var(--homeadvisor);
}
.social-houzz,
.social-houzz-hover:hover {
    color: var(--houzz);
}
.social-angieslist,
.social-angieslist-hover:hover {
    color: var(--angieslist);
}
.social-glassdoor,
.social-glassdoor-hover:hover {
    color: var(--glassdoor);
}

/***
 *      __  __              
 *     |  \/  |     ( )      
 *     | \  / | __ _ _ _ __  
 *     | |\/| |/ _` | | '_ \ 
 *     | |  | | (_| | | | | |
 *     |_|  |_|\__,_|_|_| |_|
 *                           
 *                           
 */

html {
    --max-font: 10;
    --min-font: 8;
    font-family: var(--main-font);
    font-size: var(--responsive);
}
body {
    --max-font: 16;
    --min-font: 14;
    font-size: var(--responsive);
    font-size: 1.6rem;
    font-weight: 300;
    color: var(--black-color);
    background-color: var(--eba-light);
}
body:after {
    content: "";
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 30, 44, 0.3);
    backdrop-filter: blur(0);
    -webkit-transition: backdrop-filter ease 1.4s, top ease var(--delay-normal);
    -o-transition: backdrop-filter ease 1.4s, top ease var(--delay-normal);
    transition: backdrop-filter ease 1.4s, top ease var(--delay-normal);
}
body.active_modal {
    overflow: hidden;
    padding-right: 18px;
}
body.active_modal:after {
    top: 0;
    backdrop-filter: blur(1px);
    -webkit-transition: backdrop-filter ease 1.4s, top ease var(--delay-normal);
    -o-transition: backdrop-filter ease 1.4s, top ease var(--delay-normal);
    transition: backdrop-filter ease 1.4s, top ease var(--delay-normal);
}

@-webkit-keyframes blur /* Safari and Chrome */ {
    from {
        background: rgba(24, 30, 44, 0);
        filter: blur(0);
    }
    to {
        background: rgba(24, 30, 44, 0.3);
        filter: blur(5px);
    }
}
@keyframes blur {
    from {
        background: rgba(24, 30, 44, 0);
        backdrop-filter: blur(0);
    }
    to {
        background: rgba(24, 30, 44, 0.3);
        backdrop-filter: blur(1px);
    }
}

/* Custom Scrollbar */
.custom--scrollbar::-webkit-scrollbar {
    background: transparent;
    width: 10px;
    height: 10px;
}
.custom--scrollbar::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
.custom--scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border: 4px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-radius: 40px;
}
.custom--scrollbar::-webkit-scrollbar-thumb:hover,
.custom--scrollbar::-webkit-scrollbar-thumb:active {
    border: 2px solid rgba(255, 255, 255, 0);
}
.custom--scrollbar::-webkit-scrollbar-track {
    background: transparent;
    width: 4px;
    border: none;
    border-radius: 0;
}
.custom--scrollbar::-webkit-scrollbar-track:hover,
.custom--scrollbar::-webkit-scrollbar-track:active {
    background: transparent;
    width: 5px;
}

/* Typography */
h1 {
    font-size: 250%;
}
h2 {
    font-size: 200%;
}
h3 {
    font-size: 175%;
}
h4 {
    font-size: 150%;
}
h5 {
    font-size: 125%;
}
h6 {
    font-size: 100%;
}
p {
    font-size: 100%;
    font-weight: 400;
}

/* Images */
img {
    max-width: 100%;
}

/* Buttons */
.btn {
    font-size: 1.6rem;
    border-radius: 4px;
    padding: 0.8rem 2rem;

    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.btn:hover,
.btn:active,
.btn:focus {
    color: var(--white-color);
    filter: brightness(120%);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.btn-primary {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}
.btn-primary:hover {
    color: var(--light-color);
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}
.btn.with-icon {
    position: relative;
    padding: 5px 20px 5px 50px;
    padding: 0.5rem 2rem 0.5rem 5rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border-radius: 0.4rem;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.btn.with-icon:hover,
.btn.with-icon:focus,
.btn.with-icon:active {
    background: rgba(0, 0, 0, 0.4);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.btn.with-icon .icon {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 10px;
    padding: 0 1rem;
    background: rgba(255, 255, 255, 0.2);
}
.btn.with-icon .icon em {
    margin: auto;
}
.btn.with-icon.with-animation:hover em,
.btn.with-icon.with-animation:focus em,
.btn.with-icon.with-animation:active em {
    -webkit-animation: rotating 0.8s linear infinite;
    -moz-animation: rotating 0.8s linear infinite;
    -ms-animation: rotating 0.8s linear infinite;
    -o-animation: rotating 0.8s linear infinite;
    animation: rotating 0.8s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* excerpt */
[class*="excerpt-"] {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.excerpt-1,
div.excerptx-1 p {
    -webkit-line-clamp: 1;
}
.excerpt-2,
div.excerptx-2 p {
    -webkit-line-clamp: 2;
}
.excerpt-3,
div.excerptx-3 p {
    -webkit-line-clamp: 3;
}
.excerpt-4,
div.excerptx-4 p {
    -webkit-line-clamp: 4;
}
.excerpt-5,
div.excerptx-5 p {
    -webkit-line-clamp: 5;
}

/* Forms */
.form-label {
    font-size: 20px;
    font-size: 1.6rem;
    font-weight: 400;
}
.form-control {
    padding: 0.5rem 2rem;
    font-size: 18px;
    font-size: 1.8rem;
    border-radius: 4px;
    color: var(--black-color);
    filter: brightness(140%);
    background-color: white;
    border: 1px solid var(--dark-color);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.form-control:focus {
    color: var(--white-color);
    filter: brightness(180%);
    background-color: var(--dark-color);
    border-color: #576bbb73;
    box-shadow: none;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}

/* Dropdowns */
.dropdown {
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border-radius: 0.4rem;
}
.dropdown .dropdown-toggle::after {
    content: "\f107";
    font-size: 18px;
    font-size: 1.8rem;
    font-family: "Font Awesome 5 Pro";
    border: none !important;
    margin-left: 10px;
    margin-left: 1rem;
    vertical-align: unset;
}
.dropdown > .dropdown-toggle,
.dropdown.with-toggler > .dropdown-toggle {
    border-radius: 0 4px 4px 0;
    border-radius: 0 0.4rem 0.4rem 0;
    padding: 5px 20px;
    padding: 0.5rem 2rem;
}
.dropdown.with-toggler {
    display: flex;
}
.dropdown.with-toggler > .toggler,
.toggler {
    color: var(--light-color);
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px 0px 0px 4px;
    border-radius: 0.4rem 0px 0px 0.4rem;
    padding: 5px 15px;
    padding: 0 1.5rem;
    font-size: 24px;
    font-size: 2.4rem;
}
.dropdown .dropdown-item {
    font-size: 16px;
    font-size: 1.6rem;
}

/* Expander */
[expander] {
    position: relative;
}
[expander] .content-inner {
    height: 140px;
    height: 14rem;
    overflow: hidden;
    transition: height var(--delay-normal) ease;
}
[expander] .read-more {
    color: black;

    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-top: 1rem;
    background: linear-gradient(180deg, transparent 0%, var(--dark-color) 70%);
    cursor: pointer;
}

/* On Social Media */
.onSocialMedia .title {
    font-weight: 300 !important;
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 110%;
    text-transform: capitalize;
    margin-bottom: 4px;
    margin-bottom: 0.4rem;
}
.onSocialMedia ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.onSocialMedia li {
    display: inline-block;
}
.onSocialMedia li:not(:last-child) {
    margin-right: 10px;
    margin-right: 1rem;
}
.onSocialMedia li a {
    display: flex;
    width: 40px;
    width: 4rem;
    height: 40px;
    height: 4rem;
    text-decoration: none !important;
    color: var(--black-color) !important;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    -webkit-transition: background ease var(--delay-normal);
    -o-transition: background ease var(--delay-normal);
    transition: background ease var(--delay-normal);
}
.onSocialMedia li a:hover,
.onSocialMedia li a:active,
.onSocialMedia li a:focus {
    text-decoration: none !important;
    -webkit-transition: background ease var(--delay-normal);
    -o-transition: background ease var(--delay-normal);
    transition: background ease var(--delay-normal);
}
.onSocialMedia li a:hover.facebook,
.onSocialMedia li a:active.facebook,
.onSocialMedia li a:focus.facebook {
    background: var(--facebook);
}
.onSocialMedia li a:hover.twitter,
.onSocialMedia li a:active.twitter,
.onSocialMedia li a:focus.twitter {
    background: var(--twitter);
}
.onSocialMedia li a:hover.linkedin,
.onSocialMedia li a:active.linkedin,
.onSocialMedia li a:focus.linkedin {
    background: var(--linkedin);
}
.onSocialMedia li a:hover.github,
.onSocialMedia li a:active.github,
.onSocialMedia li a:focus.github {
    background: var(--tumblr);
}
.onSocialMedia li a:hover.youtube,
.onSocialMedia li a:active.youtube,
.onSocialMedia li a:focus.youtube {
    background: var(--youtube);
}
.onSocialMedia li a:hover.android,
.onSocialMedia li a:active.android,
.onSocialMedia li a:focus.android {
    background: var(--android);
}

.onSocialMedia li a:hover.apple,
.onSocialMedia li a:active.apple,
.onSocialMedia li a:focus.apple {
    background: var(--apple);
}

.onSocialMedia li a:hover.instagram,
.onSocialMedia li a:active.instagram,
.onSocialMedia li a:focus.instagram {
    background: radial-gradient(
            circle farthest-corner at 35% 90%,
            #fec564,
            transparent 50%
        ),
        radial-gradient(
            circle farthest-corner at 0 140%,
            #fec564,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 0 -25%,
            #5258cf,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 20% -50%,
            #5258cf,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 100% 0,
            #893dc2,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 60% -20%,
            #893dc2,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 100% 100%,
            #d9317a,
            transparent
        ),
        linear-gradient(
            #6559ca,
            #bc318f 30%,
            #e33f5f 50%,
            #f77638 70%,
            #fec66d 100%
        );
}
.onSocialMedia li a em {
    font-size: 20px;
    margin: auto;
}

/*    _____ _     _      _                    
/*   / ____(_)   | |    | |                   
/*  | (___  _  __| | ___| |__   __ _ _ __ ___ 
/*   \___ \| |/ _` |/ _ \ '_ \ / _` | '__/ __|
/*   ____) | | (_| |  __/ |_) | (_| | |  \__ \
/*  |_____/|_|\__,_|\___|_.__/ \__,_|_|  |___/
/*                                            
/*                                             */
/* Sidebars */
.fixed--sidebar {
    position: fixed;
    display: block;
    top: 0;
    right: -530px;
    right: -53rem;
    left: auto;
    display: block;
    z-index: 1000;
    width: 500px;
    width: 50rem;
    max-width: 80%;
    height: 100%;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.fixed--sidebar.left {
    right: auto;
    left: -530px;
    left: -53rem;
    box-shadow: -10px 0 20px 0px rgb(0 0 0 / 10%);
}
.fixed--sidebar.active,
.fixed--sidebar.left.active {
    right: 0;
    box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.15);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.fixed--sidebar.left.active {
    right: auto;
    left: 0;
    box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.15);
}
.fixed--sidebar .close {
    position: absolute;
    top: 20px;
    top: 2rem;
    left: -30px;
    left: -3rem;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.fixed--sidebar .close:hover {
    -webkit-transform: translateX(calc(-100% + 32px)) !important;
    -ms-transform: translateX(calc(-100% + 32px)) !important;
    transform: translateX(calc(-100% + 32px)) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.fixed--sidebar.left .close {
    position: absolute;
    top: 20px;
    top: 2rem;
    left: unset;
    right: -30px;
    right: -3rem;
    padding: 5px 50px 5px 20px;
    padding: 0.5rem 5rem 0.5rem 2rem;
    -webkit-transform: translateX(0) !important;
    -ms-transform: translateX(0) !important;
    transform: translateX(0) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.fixed--sidebar.left .close:hover {
    -webkit-transform: translateX(calc(100% - 32px)) !important;
    -ms-transform: translateX(calc(100% - 32px)) !important;
    transform: translateX(calc(100% - 32px)) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.fixed--sidebar .close .icon {
    right: unset;
    left: 0;
}
.fixed--sidebar.left .close .icon {
    left: unset;
    right: 0;
}
.fixed--sidebar .fixed--sidebar_container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 50px 40px 20px;
    padding: 5rem 4rem 2rem;
    box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.25);
    background-color: var(--eba-light);
}
.fixed--sidebar .fixed--sidebar_container > * {
    position: relative;
    z-index: 1;
}

/* Menu Mobile Sidebar */
.menuMobile .header .logo {
    display: flex;
    max-width: 80%;
    margin-bottom: 50px;
    margin-bottom: 5rem;
}
.menuMobile .header > .logo > *:not(:last-child) {
    margin-right: 20px;
    margin-right: 2rem;
}
.menuMobile .header .logo .logo--icon,
.menuMobile .header .logo .logo--text {
    width: 90px;
    width: 9rem;
    fill: rgba(255, 255, 255, 255, 0.15);
    margin-top: auto;
    margin-bottom: auto;
}
.menuMobile .header .logo .logo--text {
    width: 260px;
    width: 26rem;
}
.menuMobile .header h2 {
    font-weight: 300;
    font-size: 40px;
    font-size: 4rem;
    line-height: 110%;
    text-transform: capitalize;
}
.menuMobile .header h2 strong {
    font-weight: 600;
}
.menuMobile .nav.inner {
    display: block;
    margin: 20px 0;
    margin: 2rem 0;
}
.menuMobile .nav.inner .nav-item {
    display: block;
    font-size: 20px;
    font-size: 2rem;
}
.menuMobile .nav.inner .nav-item .nav-link {
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.menuMobile .nav.inner .nav-item:hover .nav-link,
.menuMobile .nav.inner .nav-item:active .nav-link,
.menuMobile .nav.inner .nav-:focus .nav-link {
    background: rgba(255, 255, 255, 0.2);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.menuMobile .nav.inner .nav-item .nav-link em {
    background: transparent !important;
    color: var(--black-color) !important;
}
.menuMobile .nav.inner .nav-item:hover .nav-link em,
.menuMobile .nav.inner .nav-item:active .nav-link em,
.menuMobile .nav.inner .nav-:focus .nav-link em {
    background: transparent;
    color: var(--black-color) !important;
}
.menuMobile .nav.inner .nav-item .nav-link.active {
    pointer-events: none;
    background: rgba(255, 255, 255, 0.1);
}
.menuMobile .nav.inner .nav-item .nav-link.active em {
    background: transparent;
    color: var(--black-color) !important;
}

/* Advanced Search Sidebar */
.advanced_search .header {
    display: block !important;
    margin-bottom: 50px;
    margin-bottom: 5rem;
}
.advanced_search .header h2 {
    font-weight: 300;
    font-size: 40px;
    font-size: 4rem;
    line-height: 110%;
    text-transform: capitalize;
}
.advanced_search .header h2 strong {
    font-weight: 600;
}
.advanced_search .form .title {
    font-weight: bold;
    font-size: 30px;
    font-size: 3rem;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-bottom: 2rem;
}
.advanced_search .search_fancy {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}
.advanced_search .search_fancy svg {
    position: absolute;
    width: 500px;
    width: 50rem;
    bottom: -10px;
    left: -30px;
}

/* Member Data Sidebar */
.member_details-sidebar .header,
.memberInfo .header {
    display: flex;
}
.member_details-sidebar .header .avatar,
.memberInfo .header .avatar {
    width: 150px;
    height: 150px;
    margin: 0 20px auto 0;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.member_details-sidebar .header .content,
.memberInfo .header .content {
    flex: 1 0 0%;
}
.member_details-sidebar .header .content > [data-type],
.memberInfo .header .content > [data-type] {
    line-height: 1.2;
    text-transform: capitalize;
    margin-bottom: 4px;
    margin-bottom: 0.4rem;
    opacity: 0.8;
}
.memberInfo .header .content {
    margin-top: 0 !important;
}
.memberInfo .header .content > [data-type] {
    font-size: 18px;
    font-size: 1.8rem;
}
.member_details-sidebar .header .content > [data-type="name"],
.memberInfo .header .content > [data-type="name"] {
    font-weight: 500;
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 10px;
    margin-bottom: 1rem;
    opacity: 1;
}
.memberInfo .header .content > [data-type="name"] {
    font-size: 30px;
    font-size: 3rem;
}
.member_details-sidebar .header .content > [data-type] a,
.memberInfo .header .content > [data-type] a {
    text-decoration: none;
    color: inherit;
}
.member_details-sidebar .header .content > [data-type] a:hover,
.member_details-sidebar .header .content > [data-type] a:focus,
.member_details-sidebar .header .content > [data-type] a:active,
.memberInfo .header .content > [data-type] a:hover,
.memberInfo .header .content > [data-type] a:focus,
.memberInfo .header .content > [data-type] a:active {
    text-decoration: underline;
}

/*   ____            _       
/*  |  _ \          | |      
/*  | |_) | ___   __| |_   _ 
/*  |  _ < / _ \ / _` | | | |
/*  | |_) | (_) | (_| | |_| |
/*  |____/ \___/ \__,_|\__, |
/*                      __/ |
/*                     |___/  */
/* Body */
/* => Hero Header */
.hero--header {
    position: relative;
    display: flex;
    min-height: 600px;
    min-height: 60rem;
    min-width: 100%;
}
.hero--header.home {
    min-height: 54vh;
}
.hero--header > .background-container {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    background-image: url(../images/manufacturing.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}
.hero--header > .background-container:before,
.hero--header > .background-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--eba-dark);
}
.hero--header > .background-container:before {
    backdrop-filter: blur(10px);
    opacity: 0.8;
    background-color: white;
    /* background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
    ); */
}
.hero--header > .background-container:after {
    top: unset;
    bottom: 0;
    height: 50%;
    /* background: linear-gradient(
        180deg,
        rgba(24, 30, 44, 0) 0%,
        var(--eba-light) 77.6%
    ); */
}

/* Hero Header => Home */
.hero--header > .container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.hero--header > .background-container {
    background-image: url(../images/manufacturing.jpeg);
}
.hero--header .main--navbar {
    /* margin-bottom: 50px; */
}
.hero--header .content {
    margin-top: 4%;
    /* margin-bottom: 80px;
	margin-bottom: 8rem; */
}
.hero--header .content h1 {
    font-weight: 300;
    font-size: 54px;
    font-size: 5.4rem;
    line-height: 110%;
    text-transform: capitalize;
}
.hero--header .content h1 strong {
    font-weight: 600;
}
.hero--header .content .search .header {
    display: flex;
}
.hero--header .content .search .header .title {
    font-weight: bold;
    font-size: 30px;
    font-size: 3rem;
    text-transform: capitalize;
}
.btn.action,
.btn.searchAction[type="submit"] {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-size: 18px;
    font-size: 1.8rem;
    padding: 5px 20px 5px 50px;
    padding: 0.5rem 2rem 0.5rem 5rem;
    text-decoration: none;
}
.btn.action:hover,
.btn.searchAction[type="submit"]:hover,
.btn.action:focus,
.btn.searchAction[type="submit"]:focus,
.btn.action:active,
.btn.searchAction[type="submit"]:active {
    text-decoration: none !important;
}
.btn.searchAction[type="submit"] {
    letter-spacing: 1em;
    text-transform: uppercase;
}
.btn.action em,
.btn.searchAction[type="submit"] em {
    position: absolute;
    top: -6px;
    left: -6px;
    font-size: 50px;
    font-size: 5rem;
}
.hero--header .content .categories .title {
    font-weight: bold;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-bottom: 2rem;
}
.hero--header .content .categories .category--item {
    display: block;
    text-decoration: none;
    color: var(--black-color);
    padding: 10px 0;
    padding: 1rem 0;
    font-size: 18px;
    font-size: 1.8rem;
    opacity: 0.7;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
    font-weight: 400;
}
.hero--header .content .categories .category--item:hover,
.hero--header .content .categories .category--item:active,
.hero--header .content .categories .category--item:focus {
    padding: 10px 20px;
    padding: 1rem 2rem;
    background: #e5e5e5;
    opacity: 0.9;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.hero--header .content .categories .category--item em {
    margin-right: 10px 0;
    margin-right: 1rem 0;
}

/* Sections */
.section {
    margin: 20px 0;
    margin: 2rem 0;
}
.section a {
    color: inherit;
    text-decoration: none;
}
.section a:hover,
.section a:focus,
.section a:active {
    text-decoration: underline;
}
.section > .title {
    font-weight: 600;
    font-size: 20px;
    font-size: 2rem;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-bottom: 2rem;
}
.section > .content .item {
    margin-bottom: 20px;
    margin-bottom: 2rem;
}
.section.contactInformation > .content .item .icon,
.section.workInformation > .content .item .icon {
    width: 28px;
    text-align: center;
    font-size: 28px;
    font-size: 2.8rem;
    margin-right: 10px;
    margin-right: 1rem;
}
.section.contactInformation > .content .item .data .title,
.section.workInformation > .content .item .data .title {
    font-weight: 400;
    font-size: 14px;
    font-size: 1.9rem;
    line-height: 110%;
    text-transform: capitalize;
    margin-bottom: 4px;
    margin-bottom: 0.8rem;
    opacity: 0.7;
}
.section.contactInformation > .content .item .data .value,
.section.workInformation > .content .item .data .value {
    font-weight: normal;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 110%;
}

/* Inner Sidebar*/
.inner--sidebar .section:not(:last-child) {
    margin-bottom: 50px;
    margin-bottom: 5rem;
}
.inner--sidebar .section .title {
    font-weight: 600;
    font-size: 20px;
    font-size: 2rem;
    line-height: 110%;
    text-transform: capitalize;
}
.inner--sidebar .section.news--list .news--item {
    background: transparent;
    box-shadow: none;
}
.inner--sidebar .section.news--list .news--item .content {
    padding-right: 0;
    padding-left: 0;
}
.inner--sidebar .section.news--list .news--item .thumbnail {
    height: 80px;
    height: 8rem;
}

/* News List */
.news--list .news--item {
    position: relative;
    display: block;
    text-decoration: none;
    background: rgb(0 0 0 / 0.2);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}
.news--list .news--item:hover {
    text-decoration: none;
}
.news--list .news--item:not(:last-child) {
    margin-bottom: 20px;
    margin-bottom: 2rem;
}
.news--list .news--item .thumbnail {
    position: relative;
    width: 100%;
    height: 250px;
    height: 25rem;
    background-image: url(../images/placeholder.png);
    background-position: center;
    background-size: cover;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.news--list .news--item .thumbnail:before,
.news--list .news--item .thumbnail:after {
    position: absolute;
    content: "";
    opacity: 0;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.news--list .news--item .thumbnail:before,
.news--list .news--item .thumbnail:before,
.news--list .news--item .thumbnail:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(
        90deg,
        rgba(79, 172, 254, 0.5) 0%,
        rgba(0, 242, 254, 0.5) 100%
    );
    backdrop-filter: blur(1px);
}
.news--list .news--item .thumbnail:after,
.news--list .news--item .thumbnail:after,
.news--list .news--item .thumbnail:after {
    content: "\f35d";
    font-family: "Font Awesome 5 Pro";
    top: calc(50% - 20px);
    top: calc(50% - 2rem);
    right: 20px;
    right: 2rem;
    font-size: 30px;
    font-size: 3rem;
    opacity: 0;
}
.news--list .news--item:hover .thumbnail:before,
.news--list .news--item:active .thumbnail:before,
.news--list .news--item:focus .thumbnail:before,
.news--list .news--item:hover .thumbnail:after,
.news--list .news--item:active .thumbnail:after,
.news--list .news--item:focus .thumbnail:after {
    opacity: 1;
}
.news--list .news--item .content {
    padding: 20px;
    padding: 2rem;
}
.news--list .news--item .content .date {
    font-weight: 300;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 110%;
    opacity: 0.6;
    margin-bottom: 5px;
    margin-bottom: 0.5rem;
}
.news--list .news--item .content .title {
    font-weight: normal;
    font-size: 20px !important;
    font-size: 2rem !important;
    line-height: 110%;
    margin-bottom: 5px;
    margin-bottom: 0.5rem;
}
.inner--body .news--list .news--item .content .title {
    font-size: 25px !important;
    font-size: 2.5rem !important;
}
.news--list .news--item .content .excerpt {
    line-height: 1.2;
}
.news--list .news--item .content .actions .readMore,
.news--list .news--item .content .actions .onSocialMedia {
    flex: 0 0 0%;
    min-width: fit-content;
    margin: auto auto auto 0;
}
.news--list .news--item .content .actions .onSocialMedia {
    margin: auto 0 auto auto;
}
.news--list .news--item .content .actions .onSocialMedia {
    display: flex;
}
.news--list .news--item .content .actions .onSocialMedia .title {
    font-weight: 300;
    font-size: 14px !important;
    font-size: 1.4rem !important;
    line-height: 110%;
    text-transform: capitalize;
    margin: auto 10px auto auto !important;
    margin: auto 1rem auto auto !important;
}
.news--list
    .news--item
    .content
    .actions
    .onSocialMedia
    ul
    li:not(:last-child) {
    margin-right: 5px;
    margin-right: 0.5rem;
}

/* Login Page */
body.login {
    overflow-x: hidden;
}
body.login .login--inner .left {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 20px;
}
body.login .login--inner .left .background-container {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    background-image: url(../images/news01.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}
body.login .login--inner .left .background-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--eba-light);
}
body.login .login--inner .left .background-container:before {
    backdrop-filter: blur(1px);
    opacity: 0.8;
    /* background-color: white; */
}
body.login .login--inner .left .logo {
    max-width: 250px;
    max-width: 25rem;
    width: 100%;
    margin: 30px auto;
    margin: 3rem auto;
    text-align: center;
}
body.login .login--inner .left .logo .logo--icon {
    fill: rgba(255, 255, 255, 255, 0.15);
    max-width: 60%;
    margin-bottom: 14px;
    margin-bottom: 1.4rem;
}
body.login .login--inner .left .logo .logo--text {
    fill: rgba(255, 255, 255, 255, 0.15);
}
body.login .login--inner .left .big {
    font-weight: 600;
    font-size: 25px;
    font-size: 2.4rem;
    text-transform: uppercase;
}
body.login .login--inner .right {
    min-height: 100vh;
}
body.login .login--inner .right .container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
body.login .login--inner .right .container .login--form {
    margin-top: auto;
    margin-bottom: 40px;
    margin-bottom: 4rem;
    padding-top: 40px;
    padding-top: 4rem;
}
body.login .login--inner .right .container .login--form .title h1 {
    font-weight: 500;
    font-size: 30px;
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-bottom: 3rem;
}
body#root .login .login--inner .right .container .login--form .forgetPassword {
    color: inherit;
    opacity: 0.5;
    float: right;
    margin-top: 10px;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
body.login .login--inner .right .container .login--form .forgetPassword:hover,
body.login .login--inner .right .container .login--form .forgetPassword:active,
body.login .login--inner .right .container .login--form .forgetPassword:focus {
    opacity: 1;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
body.login .login--inner .right footer .container {
    padding: 20px 0;
    padding: 2rem 0;
}
body.login .login--inner .right footer .logos {
    list-style: none;
    padding: 0;
    margin: 0;
}
body.login .login--inner .right footer .logos li {
    display: inline-block;
}
body.login .login--inner .right footer .logos li:not(:last-child) {
    margin-right: 20px;
    margin-right: 2rem;
}

/*   _   _             _                
/*  | \ | |           | |               
/*  |  \| | __ ___   _| |__   __ _ _ __ 
/*  | . ` |/ _` \ \ / / '_ \ / _` | '__|
/*  | |\  | (_| |\ V /| |_) | (_| | |   
/*  |_| \_|\__,_| \_/ |_.__/ \__,_|_|   
/*                                      
/*                                       */
/* Navbar */
.main--navbar {
    padding-top: 50px;
    padding-top: 5rem;
}
.main--navbar > .logo {
    display: flex;
    max-width: 70%;
}
.main--navbar > .logo > *:not(:last-child) {
    margin-right: 20px;
    margin-right: 2rem;
}
.main--navbar > .logo .logo--icon,
.main--navbar > .logo .logo--text {
    fill: rgba(255, 255, 255, 255, 0.15);
    margin-top: auto;
    margin-bottom: auto;
}
.main--navbar > .logo .logo--icon {
    width: 9rem;
}
.main--navbar > .logo .logo--text {
    width: 26rem;
}
.main--navbar .nav--list {
    margin-left: auto;
}
.main--navbar .nav--list .nav.mobile {
    display: none;
}
.main--navbar .nav--list .nav-item {
    display: inline-flex;
}
.main--navbar .nav--list .nav.mobile .nav-item:not(:last-child) {
    padding-right: 10px;
    padding-right: 1rem;
}
.main--navbar .nav--list .nav-item .nav-link {
    margin: auto;
    text-transform: uppercase;
    color: var(--black-color);
    font-weight: 400;
}
.main--navbar .nav--list .nav.mobile .nav-item .nav-link {
    padding: 0;
}
.main--navbar .nav--list .nav-item .nav-link.btn {
    margin-left: 30px;
    margin-left: 3rem;
}
.main--navbar .nav--list .nav-item .nav-link em {
    margin-right: 10px;
    margin-right: 1rem;
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.main--navbar .nav--list .nav-item .nav-link.active em,
.main--navbar .nav--list .nav-item:hover .nav-link em,
.main--navbar .nav--list .nav-item:active .nav-link em,
.main--navbar .nav--list .nav-item:focus .nav-link em {
    -webkit-transform: rotate(-10deg) translateY(-2px) !important;
    -ms-transform: rotate(-10deg) translateY(-2px) !important;
    transform: rotate(-10deg) translateY(-2px) !important;
    background: var(--white-color);
    padding: 5px;
    padding: 0.5rem;
    border-radius: 4px;
    color: var(--black-color);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.main--navbar .nav--list .nav.mobile .nav-item .nav-link em {
    margin: 0;
    padding: 10px;
    padding: 1rem;
    font-size: 20px;
}
.main--navbar .nav--list .nav.mobile .nav-item .nav-link.active em,
.main--navbar .nav--list .nav.mobile .nav-item:hover .nav-link em,
.main--navbar .nav--list .nav.mobile .nav-item:active .nav-link em,
.main--navbar .nav--list .nav.mobile .nav-item:focus .nav-link em {
    -webkit-transform: rotate(0) translateY(0) !important;
    -ms-transform: rotate(0) translateY(0) !important;
    transform: rotate(0) translateY(0) !important;
    border-radius: 4px;
    color: var(--black-color);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
/*    _____           _   _               ____  _            _    
/*   / ____|         | | (_)             |  _ \| |          | |   
/*  | (___   ___  ___| |_ _  ___  _ __   | |_) | | ___   ___| | __
/*   \___ \ / _ \/ __| __| |/ _ \| '_ \  |  _ <| |/ _ \ / __| |/ /
/*   ____) |  __/ (__| |_| | (_) | | | | | |_) | | (_) | (__|   < 
/*  |_____/ \___|\___|\__|_|\___/|_| |_| |____/|_|\___/ \___|_|\_\
/*                                                                
/*                                                                 */
/* Section Block */
.section-block {
    position: relative;
    margin: 50px 0;
    margin: 5rem 0;
}
.section-block .title {
    position: relative;
    margin-bottom: 50px;
    margin-bottom: 5rem;
}
.section-block .title[data-shadowtext]:before {
    content: attr(data-shadowtext);
    position: absolute;
    bottom: -50px;
    bottom: -5rem;
    left: 50px;
    left: 5rem;
    font-weight: 700;
    font-size: 80px;
    font-size: 7rem;
    opacity: 0.05;
    z-index: -1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding-right: 30px;
}
.section-block .title h2 {
    display: inline-block;
    font-weight: 200;
    font-size: 40px;
    font-size: 4rem;
    text-transform: capitalize;
    /* background-color: var(--dark-color); */
    padding: 5px 10px;
    padding: 0.5rem 1rem;
    margin: 0;
}
.section-block .title strong {
    font-weight: 700;
}

/* Members List Block */
.members--list .header--forList {
    margin-bottom: 40px;
    margin-bottom: 4rem;
}
.members--list .header--forList .options {
    display: flex;
}
.members--list .header--forList .options .option {
    display: inline-block;
}
.members--list .header--forList .options .option:not(:last-child) {
    margin-right: 30px;
    margin-right: 3rem;
}
.members--list .header--forList .options .option .header {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 5px;
    margin-bottom: 0.5rem;
}
.members--list .header--forList .alphabetic-list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    margin: 2rem 0 0;
}
.members--list .header--forList .alphabetic-list li {
    display: inline-table;
    margin-bottom: 10px;
    margin-bottom: 1rem;
}
.members--list .header--forList .alphabetic-list li:not(:last-child) {
    margin-right: 5px;
    margin-right: 0.5rem;
}
.members--list .header--forList .alphabetic-list li a {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 32px;
    width: 3.2rem;
    height: 32px;
    height: 3.2rem;
    border-radius: 50%;
    margin: auto;
    font-size: 20px;
    font-size: 2rem;
    color: var(--black-color);
    text-decoration: none;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0.5;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
    font-weight: 500;
}

.active a {
    /* background-color: #ffffff38;
    border-radius: 50%; */
    opacity: 1;
}

.members--list .header--forList .alphabetic-list li .active,
.members--list .header--forList .alphabetic-list li a:active,
.members--list .header--forList .alphabetic-list li a:focus,
.members--list .header--forList .alphabetic-list li a:hover {
    opacity: 1;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.members--list .members--items .member--item,
.members--slider .member--item {
    display: flex;
    cursor: pointer;
}
.members--list .members--items .member--item .avatar,
.members--slider .member--item .avatar {
    position: relative;

    width: 155px !important;
    height: 155px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 20px auto 0;
    margin: 0 2rem auto 0;
    border-radius: 50%;
}
.members--list .members--items .member--item .avatar .hover,
.members--slider .member--item .avatar .hover {
    position: absolute;
    display: flex;
    text-align: center;
    font-size: 18px;
    font-size: 1.8rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--white-color);
    background-color: var(--bs-primary);
    border-radius: 50%;
    border: 1px solid var(--bs-primary);
    transform: scale(0);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.members--list .members--items .member--item:hover .avatar .hover,
.members--slider .member--item:hover .avatar .hover {
    transform: scale(1.1);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.members--list .members--items .member--item .avatar .hover em,
.members--slider .member--item .avatar .hover em {
    margin: auto;
    font-size: 20px;
    font-size: 2rem;
}
.members--list .members--items .member--item .content,
.members--slider .member--item .content {
    margin: auto 0;
    flex: 1 0 0%;
}
.members--list .members--items .member--item .content [data-type="name"],
.members--slider .member--item .content [data-type="name"] {
    font-weight: 500;
    font-size: 24px;
    font-size: 2.1rem;
    text-transform: capitalize;
    margin-bottom: 0.5px;
    margin-bottom: 0.5rem;
}
.members--list .members--items .member--item .content [data-type="role"],
.members--slider .member--item .content [data-type="role"],
.members--list
    .members--items
    .member--item
    .content
    [data-type="company-name"],
.members--slider .member--item .content [data-type="company-name"] {
    text-transform: capitalize;
    font-weight: 400;
}
.members--list .members--items .member--item .content [data-type="sector"] a,
.members--slider .member--item .content [data-type="sector"] a {
    display: inline-block;
    padding: 4px 10px;
    padding: 0.4rem 1rem;
    margin-top: 10px;
    margin-top: 1rem;
    text-decoration: none;
    color: var(--light-color);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.members--list
    .members--items
    .member--item
    .content
    [data-type="sector"]
    a:hover,
.members--list
    .members--items
    .member--item
    .content
    [data-type="sector"]
    a:active,
.members--list
    .members--items
    .member--item
    .content
    [data-type="sector"]
    a:focus {
    background: rgba(255, 255, 255, 0.4);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.pagination ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.pagination ul li {
    display: inline-block;
}
.pagination ul li a {
    display: flex;
    height: 30px;
    min-width: 30px;
    background: rgba(255, 255, 255, 0.2);
    color: inherit;
    text-decoration: none !important;
    font-size: 16px;
    font-size: 1.6rem;
    border: none;
    margin: 0 !important;
    z-index: unset !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.pagination ul li a:hover,
.pagination ul li a:focus,
.pagination ul li a:active {
    color: inherit;
    text-decoration: none !important;
    background: rgba(255, 255, 255, 0.3);
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
.pagination ul li.active a {
    background: rgba(255, 255, 255, 1) !important;
    color: var(--black-color) !important;
    transform: scale(1.2);
    pointer-events: none;
}
.pagination ul li.disabled a {
    background: rgba(255, 255, 255, 0.2) !important;
}
.pagination ul li a > * {
    margin: auto;
    line-height: 0;
}

/* Members Slider */
.members--slider .splide__slide {
    display: flex;
}
.members--slider .splide__arrows {
    display: none;
}
.members--slider .splide__pagination .splide__pagination__page {
    height: 5px;
    width: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: none;
    background: rgba(255, 255, 255, 0.05);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-transition: background ease var(--delay-normal);
    -o-transition: background ease var(--delay-normal);
    transition: background ease var(--delay-normal);
}
.members--slider .splide__pagination .splide__pagination__page:hover,
.members--slider .splide__pagination .splide__pagination__page:active,
.members--slider .splide__pagination .splide__pagination__page:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    -webkit-transition: background ease var(--delay-normal);
    -o-transition: background ease var(--delay-normal);
    transition: background ease var(--delay-normal);
}
.members--slider .splide__pagination .splide__pagination__page.is-active {
    background: black;
    -webkit-transition: background ease var(--delay-normal);
    -o-transition: background ease var(--delay-normal);
    transition: background ease var(--delay-normal);
}
.members--slider .member--item {
    display: block;
    text-align: center;
    color: inherit;
    text-decoration: none;
    margin: auto;
    padding: 20px;
}
.members--slider .member--item .avatar {
    /* width: 50%; */
    width: 150px !important;
    height: 150px;
    /* padding-bottom: 15%; */
    margin: 20px auto;
    -webkit-transition: width ease var(--delay-normal),
        padding-bottom ease var(--delay-normal);
    -o-transition: width ease var(--delay-normal),
        padding-bottom ease var(--delay-normal);
    transition: width ease var(--delay-normal),
        padding-bottom ease var(--delay-normal);
}

.members--slider .splide__pagination {
    background-color: #00000021;
    width: 200px;
    margin-bottom: -5px;
    display: none;
}

.members--slider .splide__slider .splide__arrows {
    display: inline;
}
.members--slider .splide__slide.is-active.is-visible .member--item .avatar {
    /* width: 50% ; */
    /* padding-bottom: 80%; */
    width: 150px;

    -webkit-transition: width ease var(--delay-normal),
        padding-bottom ease var(--delay-normal);
    -o-transition: width ease var(--delay-normal),
        padding-bottom ease var(--delay-normal);
    transition: width ease var(--delay-normal),
        padding-bottom ease var(--delay-normal);
}
.members--slider .member--item:hover .avatar .hover {
    transform: scale(1.05);
}
.members--slider .member--item .avatar .hover em {
    font-size: 26px;
}
.members--slider
    .splide__slide.is-active.is-visible
    .member--item
    .avatar
    .hover
    em {
    font-size: 40px;
}

/*   ______          _            
/*  |  ____|        | |           
/*  | |__ ___   ___ | |_ ___ _ __ 
/*  |  __/ _ \ / _ \| __/ _ \ '__|
/*  | | | (_) | (_) | ||  __/ |   
/*  |_|  \___/ \___/ \__\___|_|   
/*                                
/*                                 */
/* Footer */
footer {
    position: relative;
}
footer > .background-container {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    background-image: url(../images/manufacturing.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}
footer > .background-container:before,
footer > .background-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--eba-light);
}
footer > .background-container:before {
    backdrop-filter: blur(1px);
    opacity: 0.8;
    /* background-color: white; */
}
footer > .background-container:after {
    top: -10px;
    height: 50%;
    transform: rotate(-180deg);
    background: linear-gradient(
        180deg,
        rgba(24, 30, 44, 0) 0%,
        var(--eba-light) 77.6%
    );
}
footer .container {
    padding: 100px 0 20px;
    padding: 10rem 0 2rem;
}
footer .siteInfo .logo {
    margin: 20px;
    margin: 2rem;
}
footer .siteInfo .logo .logo--icon,
footer .siteInfo .logo .logo--text {
    display: block;
    fill: var(--light-color);
    margin: auto;
}
footer .siteInfo .logo .logo--icon {
    max-width: 150px;
    max-width: 15rem;
}
footer .siteInfo .logo .logo--text {
    margin: 20px auto 0;
    margin: 2rem auto 0;
    max-width: 280px;
    max-width: 28rem;
}
footer .siteInfo .big {
    font-weight: 400;
    font-size: 26px;
    font-size: 2.6rem;
    text-transform: capitalize;
}
footer .copyrights {
    /* margin-top: 50px;
	margin-top: 5rem; */
    font-size: 16px;
    font-size: 1.6rem;
}
footer .copyrights a {
    color: var(--white-color);
    text-decoration: none;
    opacity: 0.7;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
footer .copyrights a:hover,
footer .copyrights a:active,
footer .copyrights a:focus {
    text-decoration: underline;
    opacity: 1;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
footer #backToTop {
    position: fixed;
    bottom: 50px;
    bottom: 5rem;
    right: 0;
    opacity: 0;
    -webkit-transform: translateX(calc(100% - 30px)) !important;
    -ms-transform: translateX(calc(100% - 30px)) !important;
    transform: translateX(calc(100% - 30px)) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}
footer #backToTop:hover,
footer #backToTop:focus,
footer #backToTop:active {
    -webkit-transform: translateX(2%) !important;
    -ms-transform: translateX(2%) !important;
    transform: translateX(2%) !important;
    -webkit-transition: ease var(--delay-normal);
    -o-transition: ease var(--delay-normal);
    transition: ease var(--delay-normal);
}

/*   _____                                 _                               
/*  |  __ \                               (_)                              
/*  | |__) |___  ___ _ __   ___  _ __  ___ ___   _____ _ __   ___  ___ ___ 
/*  |  _  // _ \/ __| '_ \ / _ \| '_ \/ __| \ \ / / _ \ '_ \ / _ \/ __/ __|
/*  | | \ \  __/\__ \ |_) | (_) | | | \__ \ |\ V /  __/ | | |  __/\__ \__ \
/*  |_|  \_\___||___/ .__/ \___/|_| |_|___/_| \_/ \___|_| |_|\___||___/___/
/*                  | |                                                    
/*                  |_|                                                     */
/* Responsiveness */
/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

@media (max-width: 420.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .main--navbar .nav--list .nav.desktop {
        display: none;
    }
    .main--navbar .nav--list .nav.mobile {
        display: block;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
}
/* Large devices (desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px) {
}
/* Large devices (desktops, less than 1600px) */
@media (max-width: 1599.98px) {
}

/* Extra large devices (large desktops) */
/* No media query since the extra-large breakpoint has no upper bound on its width */
