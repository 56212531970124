@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Light.eot');
    src: local('Work Sans Light'), local('WorkSans-Light'),
        url('WorkSans-Light.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-Light.woff2') format('woff2'),
        url('WorkSans-Light.woff') format('woff'),
        url('WorkSans-Light.ttf') format('truetype'),
        url('WorkSans-Light.svg#WorkSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Black.eot');
    src: local('Work Sans Black'), local('WorkSans-Black'),
        url('WorkSans-Black.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-Black.woff2') format('woff2'),
        url('WorkSans-Black.woff') format('woff'),
        url('WorkSans-Black.ttf') format('truetype'),
        url('WorkSans-Black.svg#WorkSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Bold.eot');
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
        url('WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-Bold.woff2') format('woff2'),
        url('WorkSans-Bold.woff') format('woff'),
        url('WorkSans-Bold.ttf') format('truetype'),
        url('WorkSans-Bold.svg#WorkSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-SemiBold.eot');
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
        url('WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-SemiBold.woff2') format('woff2'),
        url('WorkSans-SemiBold.woff') format('woff'),
        url('WorkSans-SemiBold.ttf') format('truetype'),
        url('WorkSans-SemiBold.svg#WorkSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Regular.eot');
    src: local('Work Sans Regular'), local('WorkSans-Regular'),
        url('WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-Regular.woff2') format('woff2'),
        url('WorkSans-Regular.woff') format('woff'),
        url('WorkSans-Regular.ttf') format('truetype'),
        url('WorkSans-Regular.svg#WorkSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Medium.eot');
    src: local('Work Sans Medium'), local('WorkSans-Medium'),
        url('WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-Medium.woff2') format('woff2'),
        url('WorkSans-Medium.woff') format('woff'),
        url('WorkSans-Medium.ttf') format('truetype'),
        url('WorkSans-Medium.svg#WorkSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('WorkSans-Thin.eot');
    src: local('Work Sans Thin'), local('WorkSans-Thin'),
        url('WorkSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('WorkSans-Thin.woff2') format('woff2'),
        url('WorkSans-Thin.woff') format('woff'),
        url('WorkSans-Thin.ttf') format('truetype'),
        url('WorkSans-Thin.svg#WorkSans-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

